import Home from "./Home";
import About from "./About";
import Services from "./Services";
//import Blog from "./Blog";
//import BlogDetails from "./BlogDetails";
import Portfolio from "./Portfolio";
import PortfolioDetails from "./PortfolioDetails";
//import Team from "./Team";
import Contact from "./Contact";

const routes = [
  { path: "/", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/services", component: <Services /> },
  //{ path: "/blog", component: <Blog /> },
  //{ path: "/blog/:slug", component: <BlogDetails /> },
  { path: "/portfolio", component: <Portfolio /> },
  { path: "/portfolio/:slug", component: <PortfolioDetails /> },
  //{ path: "/team", component: <Team /> },
  { path: "/contact", component: <Contact /> },
];

export default routes;
